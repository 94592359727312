import AuthService from '@/services/auth';

const user = JSON.parse(localStorage.getItem('user'));
const initialUser = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: {token:''} };

export default {
  namespaced: false,
  state: {
    ...initialUser
  },
  actions: {
    login({ dispatch, commit}, param) {
        commit('loginRequest', {email: param.email});

        AuthService.postSignin(param)
            .then(
                user => {
                    commit('loginSuccess', user.data);
                    localStorage.setItem('user', JSON.stringify(user.data));
                    //router.push();
                }
            )
            .catch((err)=>{
              commit('loginFailure');
              dispatch('alert/error', err, { root: true });
            })
    },
    logout({ commit }) {
        localStorage.removeItem('user');
        commit('logout');
    },
    refreshToken({commit},param){
      return new Promise((resolve, reject) => {
        AuthService.postRefresh(param)
        .then(function (response) {
          let user = JSON.parse(localStorage.getItem('user'));
          if(user === null){
            console.log(response.data)
            user = response.data
          }
          else{
            user.token = response.data.token
          }
          localStorage.setItem('user', JSON.stringify(user));
          commit('loginSuccess', user);
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        });
      }, error => console.log(error))
    },
    testConnect({dispatch}){
      AuthService.getTestConnect().then(()=>{
      }).catch((err)=>{
        dispatch('alert/error', err, { root: true });
      })
    },
  },
  mutations: {
    loginRequest(state, user) {
        state.status = { loggingIn: false };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = {token:''};
    },
    logout(state) {
        state.status = {};
        state.user = {token:''};
    },
    mutRoot(state) {
        state.root = {token:''};
    }
  }
}
