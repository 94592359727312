import Api from '@/services/api'

const basic = 'auth';
export default {
    postSignin (params) {
        return Api.default().post('/'+basic+'/signin',params)
    },
    postRefresh (params) {
        return Api.default().post('/'+basic+'/refreshToken',params)
    },
    getTestConnect () {
        return Api.default().get('/'+basic+'/testConnect')
    }
}
