<template>
  <div>
    <p class="title">Settings</p>
    <hr class="nav-color">
    <b-row>
      <b-col >
        <p class="title2 first">Notifications</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col offset-xl="4" xl="2">
        <b-button v-if="logger.enableEmail" class="redButton " variant="danger" @click="notificationClick">Turn off notifications</b-button>
        <b-button v-if="!logger.enableEmail" class="greenButton " variant="success" @click="notificationClick">Turn on notifications</b-button>
      </b-col>
      <b-col xl="2">
        <b-button class="greenButton " variant="success" @click="getUrl">Reset Gmail token</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col offset-xl="4" xl="4">
        <b-form-group class="second" label="Flow Limit" label-for="input-flow-limit">
          <b-form-select
            id="input-flow-limit"
            v-model="flowLimit"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col offset-xl="2" xl="8">
        <b-form-group class="second" label="Email List">
          <b-form-tags input-id="tags-basic" v-model="value" class="mb-2" placeholder="Enter a new email address ..."></b-form-tags>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col >
        <p class="title2 second">Data</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col offset-xl="5" xl="2">
        <p class="">Average flows</p>
        <b-button class="greenButton" variant="success" @click="recalStatClick">Recalculate the last 48 hours</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="end" offset-xl="4" xl="4">
        <p class="second">Rain data</p>
        <b-form-file v-model="file" accept="text/csv"></b-form-file>
        <div>
          <b-spinner v-if="spinnerOn" label="Loading..."></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'setting',
    components: {

    },
    data() {
      return {
        flowLimit: null,
        options:[],
        value: ['apple', 'orange', 'banana'],
        emailList:'',
        file: null,
        timeOffset: 4,
        spinnerOn : false
      };
    },
    computed: {
      ...mapState({
        logger: state => state.data.logger,
        postDataOver: state => state.data.postDataOver
      }),
    },
    watch:{
      logger(){
        this.flowLimit = this.logger.flowLimit
        this.emailList = this.logger.emailList
        this.value = this.logger.emailList.split(',')
      },
      value(){
        let emailList = ''
        for(let i = 0; i < this.value.length; i++){
          if(i === (this.value.length - 1)){
            emailList += this.value[i]
          }
          else{
            emailList += this.value[i] + ','
          }
        }
        if(this.emailList !== emailList){
          this.emailList = emailList
          this.putLogger({'emailList':emailList})
        }
      },
      flowLimit(newValue, oldValue){
        if(newValue !== oldValue && oldValue !== null){
          this.putLogger({'flowLimit':newValue})
        }
      },
      async file(){
        const data = await this.parseCSV()
        this.spinnerOn = true;
        this.postData({data:data})
      },
      postDataOver(){
        this.spinnerOn = false;
      }
    },
    methods:{
      ...mapActions([
        'getLogger',
        'putLogger',
        'postRecalStat',
        'getUrl',
        'postData'
      ]),
      createOptions(){
        this.options = []

        const minValue = 0
        const number = 100
        const step = 1
        for(let i = 0; i <= number; i++){
          this.options.push({
            value: Math.round((minValue + i * step)*100)/100,
            text: Math.round((minValue + i * step)*100)/100 + ' L/S'
          })
        }
      },
      notificationClick(){
        this.putLogger({'enableEmail':!this.logger.enableEmail})
      },
      recalStatClick(){
        let minDate = new Date();
        let maxDate = new Date();
        minDate.setHours(minDate.getHours() - 48);
        this.postRecalStat({'loggerId':'logger1','minDate':minDate,'maxDate':maxDate})
      },
      parseCSV(){
        return new Promise((resolve) => {
          console.log(this.file.name)
          const reader = new FileReader();
          reader.onload = function() {
            const csv = reader.result;
            const lines=csv.split("\n");
            const headers=lines[1].split(",");
            let result = [];
            for(var i=2;i<lines.length;i++){
                var obj = {};
                var currentline=lines[i].split(",");

                for(var j=0;j<headers.length;j++){
                    obj[headers[j]] = currentline[j];
                }
                let time = ''
                let hour
                //console.log(obj.Date+' '+obj.Time)
                if(obj.Time.indexOf('AM') !== -1){
                  obj.Time.replace(' AM','')
                  hour = parseInt(obj.Time.split(':')[0])
                  //console.log(parseInt(obj.Time.split(':')[0]))
                  if(hour === 12){
                    hour = 0
                  }
                }
                else{
                  obj.Time.replace(' PM','')
                  hour = parseInt(obj.Time.split(':')[0])
                  if(hour === 12){
                    //hour += 0
                  }
                  else{
                    hour = hour + 12
                  }

                  if(hour >= 24 ){
                    hour -= 24
                  }
                }
                //console.log(hour)
                if(hour < 10){
                  time = 'T0'+hour+':00:00'
                }
                else{
                  time = 'T'+hour+':00:00'
                }
                //obj.DateTime = "20"+obj.Date+time
                obj.DateTime = new Date("20"+obj.Date+time)
                //obj.DateTime.setHours(obj.DateTime.getHours() - 1)
                //console.log(obj.DateTime)
                let data = {}
                data.date = obj.DateTime
                data.loggerId = 'rainLogger1'
                data.rain = obj.Rain
                result.push(data);
            }
            resolve(result)
          }
          reader.readAsText(this.file);
        });
      }
    },
    mounted() {
      this.createOptions()
      this.getLogger()
    },
  }
</script>

<style >
  .greenButton{
    background-color: #94d60a !important;
    font-size: 20px !important;
    width: 100% !important;
    height: 100px !important;
  }

  .redButton{
    background-color: #FF4C33 !important;
    font-size: 20px !important;
    width: 100% !important;
    height: 100px !important;
  }
</style>
