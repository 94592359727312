import Vue from 'vue'
import Vuex from 'vuex'

import alert from '@/store/alert.module'
import authentication from '@/store/authentication.module'
import data from '@/store/data.module'
import googletoken from '@/store/googletoken.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    data,
    googletoken
  },
  state:{},
  getters:{},
  mutations:{},
  actions:{}
})
