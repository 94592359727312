<template>
  <div>
    <p class="desc">{{title}}</p>
    <p class="value">{{Math.round(value*100)/100}} {{unit}}</p>
    <p class="desc" v-if="date">{{new Date(date).toLocaleString()}}</p>
  </div>
</template>

<script>

  export default {
    components: {

    },
    props: {
      title: String,
      value: Number,
      unit: String,
      date: String,
    },
    data() {
      return {

      };
    },
    computed:{},
    watch:{
    },
    methods:{

    },
    mounted() {

    },
    created(){

    }

  };

</script>

<style scoped>
  .first{

  }

  .value{
    text-align: center;
    font-size: 30px;
    margin-bottom: 0;
  }

  .desc{
    text-align: center;
    font-size: 19px;
    margin-bottom: 0;
  }
</style>
