import Service from '@/services/googletoken';

/*const user = JSON.parse(localStorage.getItem('user'));
const initialUser = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: {token:''} };*/

export default {
  namespaced: false,
  state: {},
  actions: {
    getUrl({ dispatch}) {
      Service.getUrl().then( (body) => {
        window.open(body.data, '_blank');
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    }

  },
  mutations: {

  }
}
