<template>
  <div>
    <b-row>
      <b-col xl="12">
        <SigninForm type="signin"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import SigninForm from '@/components/SigninForm';
  import {mapState} from 'vuex';

  //const { VUE_APP_MODE, VUE_APP_PLATFORM } = process.env;

  export default {
    name: 'home',
    components: {
      SigninForm
    },
    data() {
      return {
        show: true
      };
    },
    computed:{
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn
      })
    },
    watch:{
      loggedIn(){
        this.checkLoggedIn()
      }
    },
    methods:{
      checkLoggedIn(){
        if(this.loggedIn){
          this.$router.push('/')
        }
      }
    },
    mounted() {
      this.checkLoggedIn()
    },
    created() {
    },
    destroyed() {
    },

  };

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
