<template>
  <div>
    <b-container fluid>
      <p class="title">Moose Creek Pump</p>
      <hr class="nav-color">
      <b-row>
        <b-col offset-xl="5" xl="2">
          <b-form-group
            class="first"
            label="Pump type"
          >
            <b-form-select v-model="pumpId" :options="pumpOption"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="pumpId !== 'pumptest'">
        <b-col offset-xl="5" xl="2">
          <b-form-group
            class="first"
            label="Pump flow"
            description="The flow has to be set by the operator before pressing de button"
          >
            <b-form-select v-model="selected" :options="options"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="pumpId !== 'pumptest'">
        <b-col offset-xl="4" xl="4">
          <b-button v-if="lastPumpValue.status==='stopped' && (lastAvgFlowData >= minFlow && selected <= ((lastAvgFlowData >= minFlow)?lastAvgFlowData/5:0))" @click="clickPump" class="startButton second" variant="success">Start pump</b-button>
          <b-button v-if="lastPumpValue.status==='started'" @click="clickPump" class="stopButton second" variant="danger">Stop pump</b-button>
          <b-button v-if="lastPumpValue.status==='stopped' && lastAvgFlowData < minFlow" @click="clickPump" class="warningButton second" variant="warning">The pump should not be running</b-button>
          <b-button v-if="lastPumpValue.status==='stopped' && lastAvgFlowData >= minFlow && selected > ((lastAvgFlowData >= minFlow)?lastAvgFlowData/5:0)" @click="clickPump" class="warningButton second" variant="warning">Selected pump flow too high</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-xl="2" xl="4">
          <Value v-if="lastValues.lastAvgFlowData" class="second"
            title="Last Average Flow"
            :value="lastValues.lastAvgFlowData.flow"
            unit="L/S"
            :date="lastValues.lastAvgFlowData.date"
          />
        </b-col>
        <b-col xl="4">
          <Value v-if="lastValues.lastAvgFlowData" class="second"
            title="Max Pump Flow Allowed"
            :value="(lastValues.lastAvgFlowData.flow >= minFlow)?lastValues.lastAvgFlowData.flow/5:0"
            unit="L/S"
          />
        </b-col>
      </b-row>

      <p class="title">Statistics</p>
      <hr class="nav-color">

      <b-tabs content-class="mt-3">
        <b-tab title="Flow" @click="getHistory" active>
          <b-row>
            <b-col md="3">
              <Value v-if="lastValues.lastFlowData" class="second"
                title="Creek Flow"
                :value="lastValues.lastFlowData.flow"
                unit="L/S"
                :date="lastValues.lastFlowData.date"
              />
            </b-col>
            <b-col md="3">
              <Value v-if="lastValues.lastFlowData" class="second"
                title="Water Temp."
                :value="lastValues.lastFlowData.waterTemp"
                unit="°C"
                :date="lastValues.lastFlowData.date"
              />
            </b-col>
            <b-col md="3">
              <Value v-if="lastValues.lastFlowData" class="second"
                title="Air Pressure"
                :value="lastValues.lastFlowData.pressure"
                unit="kPa"
                :date="lastValues.lastFlowData.date"
              />
            </b-col>
            <b-col md="3">
              <Value v-if="lastValues.lastFlowData" class="second"
                title="Air Temp."
                :value="lastValues.lastFlowData.ampTemp"
                unit="°C"
                :date="lastValues.lastFlowData.date"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <p class="title2 second">12 hours Average</p>
            </b-col>
          </b-row>
          <b-row >
            <b-col offset-xl="2" xl="3">
              <b-form-group label-cols="4" label-cols-lg="2" label="Min:">
                <b-form-input
                  v-model="minDate"
                  type="date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="3">
              <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
                <b-form-input
                  v-model="maxDate"
                  type="date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="2">
              <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
                <b-button @click="getHistory">Apply</b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <VueApexCharts class="" :height="500" type="area" :options="chartOptions" :series="series"></VueApexCharts>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <p class="title2 second">Pump flows</p>
            </b-col>
          </b-row>
          <b-row >
            <b-col offset-xl="2" xl="3">
              <b-form-group label-cols="4" label-cols-lg="2" label="Min:">
                <b-form-input
                  v-model="minDate"
                  type="date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="3">
              <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
                <b-form-input
                  v-model="maxDate"
                  type="date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="2">
              <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
                <b-button @click="getHistory">Apply</b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row >
            <b-col>
              <Value class="second"
                title="Total Discharged during this period"
                :value="totalPumpDischarged"
                unit="L"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <VueApexCharts class="end" :height="200" type="area" :options="pumpChartOptions" :series="pumpSeries"></VueApexCharts>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Raw data" @click="getRealTime">
          <b-row>
            <b-col offset-xl="2" xl="8">
              <p class="title2 second">Real-time flows</p>
            </b-col>
          </b-row>
          <b-row >
            <b-col offset-xl="2" xl="3">
              <b-form-group label-cols="4" label-cols-lg="2" label="Min:">
                <b-form-input
                  v-model="minDate"
                  type="date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="3">
              <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
                <b-form-input
                  v-model="maxDate"
                  type="date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="2">
              <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
                <b-button @click="getRealTime">Apply</b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <VueApexCharts class="" :height="500" type="area" :options="chartOptions" :series="rtseries"></VueApexCharts>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <p class="title2 second">Rain Data</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-xl="2" xl="8">
              <VueApexCharts class="" :height="200" type="area" :options="rainOptions" :series="rainserie"></VueApexCharts>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  //import Seperator from '@/components/Seperator.vue';
  import Value from '@/components/Value.vue';
  import VueApexCharts from 'vue-apexcharts'

  export default {
    name: 'home',
    components: {
      //Seperator,
      VueApexCharts,
      Value
    },
    data() {
      return {
        minFlow: 60,
        selected: 12,
        minDate:undefined,
        minFormatedDate:undefined,
        maxDate:undefined,
        maxFormatedDate:undefined,
        lastAvgFlowData:0,
        pumpId:'pumptest',
        pumpOption:[
          { value: 'pumptest', text: 'Automatic pump' },
          { value: 'pump01', text: 'Manual pump' },
        ],
        options: [
          { value: 10, text: '10 L/S' },
          { value: 10.5, text: '10.5 L/S' },
          { value: 11, text: '11 L/S' },
          { value: 11.5, text: '11.5 L/S' },
          { value: 12, text: '12 L/S' },
          { value: 12.5, text: '12.5 L/S' },
          { value: 13, text: '13 L/S' },
          { value: 13.5, text: '13.5 L/S' },
        ],
        pumpSeries:[
          {
            name: 'Pump Flow',
            type: 'column',
            data: []
          }
        ],
        pumpChartOptions: {
          chart: {
            height: 350,
            type: 'area',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [3]
          },
          xaxis: {
             type: 'datetime'
          },
          yaxis: [
            {
              seriesName: 'Pump Flow',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  colors: '#008FFB',
                }
              },
              title: {
                text: "Flow (L/S)",
                style: {
                  color: '#008FFB',
                }
              },
              tooltip: {
                enabled: true
              },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
        series: [
          {
            name: 'Creek Average Flow',
            data: []
          },
          {
            name: 'Water Temperature',
            data: []
          },
          {
            name: 'Air Pressure',
            data: []
          },
        ],
        rtseries: [
          {
            name: 'Creek Flow',
            data: []
          },
          {
            name: 'Water Temperature',
            data: []
          },
          {
            name: 'Air Pressure',
            data: []
          },
        ],
        rainserie: [
          {
            name: 'Rain',
            data: []
          }
        ],
        rainOptions: {
          chart: {
            height: 350,
            type: 'area',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [3]
          },
          xaxis: {
             type: 'datetime'
          },
          yaxis: [
            {
              seriesName: 'Rain',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  colors: '#008FFB',
                }
              },
              title: {
                text: "Rain (mm)",
                style: {
                  color: '#008FFB',
                }
              },
              tooltip: {
                enabled: true
              },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
        chartOptions: {
          chart: {
            height: 350,
            type: 'area',
            stacked: false
          },
          animations: {
            enabled: true
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 3, 5, 5]
          },
          xaxis: {
             type: 'datetime'
          },
          yaxis: [
            {
              seriesName: 'Creek Average Flow',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  colors: '#008FFB',
                }
              },
              title: {
                text: "Flow (L/S)",
                style: {
                  color: '#008FFB',
                }
              },
              tooltip: {
                enabled: true
              },
            },
            {
              seriesName: 'Water Temperature',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#00E396'
              },
              labels: {
                style: {
                  colors: '#00E396',
                },
              },
              title: {
                text: "Temperature (°C)",
                style: {
                  color: '#00E396',
                }
              },
            },
            {
              seriesName: 'Air Pressure',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#FEB019'
              },
              labels: {
                style: {
                  colors: '#FEB019',
                },
              },
              title: {
                text: "Pressure (kPa)",
                style: {
                  color: '#FEB019',
                }
              },
              min:99,
              max:103
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        },

      };
    },
    computed:{
      ...mapState({
        status: state => state.authentication.status,
        values: state => state.data.values,
        lastValues: state => state.data.lastValues,
        lastPumpValue: state => state.data.lastPumpValue,
        pumpValues: state => state.data.pumpValues,
        totalPumpDischarged: state => state.data.totalPumpDischarged
      })
    },
    watch:{
      lastValues(){
        this.setLastValue()
      },
      values(){
        this.createChart()
      },
      pumpValues(){
        this.createPumpChart()
      },
      pumpId(){
        this.getHistory()
      },
      minDate(){
        let date = new Date(this.minDate)
        this.minFormatedDate = date.getTime()+date.getTimezoneOffset() * 60 * 1000
        this.minFormatedDate = new Date(this.minFormatedDate)
      },
      maxDate(){
        let date = new Date(this.maxDate)
        this.maxFormatedDate = date.getTime()+date.getTimezoneOffset() * 60 * 1000
        this.maxFormatedDate = new Date(this.maxFormatedDate)
      }
    },
    methods:{
      ...mapActions([
        'getLastData',
        'getLastPump',
        'getPumpValues',
        'postPump',
        'getData',
        'getLogger'
      ]),
      getlastValues(){
        this.getLastData({valueType:'lastFlowData',param:{loggerId:'logger1',type:'rt'}})
        this.getLastData({valueType:'lastAvgFlowData',param:{loggerId:'logger1',type:'avg'}})
      },
      setLastValue(){
        if(this.lastValues.lastAvgFlowData && this.lastValues.lastAvgFlowData.flow){
          this.lastAvgFlowData = this.lastValues.lastAvgFlowData.flow
          //console.log('here1')
        }
        //console.log(this.lastAvgFlowData)

        this.setOptions()
      },
      setOptions(){
        if(this.lastPumpValue.flow){
          this.selected = this.lastPumpValue.flow
        }
        else{
          this.selected = 12
        }
      },
      createOptions(){
        this.options = []

        const minValue = 12
        const number = 38
        const step = 1
        for(let i = 0; i <= number; i++){
          this.options.push({
            value: Math.round((minValue + i * step)*100)/100,
            text: Math.round((minValue + i * step)*100)/100 + ' L/S'
          })
        }
      },
      clickPump(){
        this.postPump({param:{pumpId:this.pumpId,flow:this.selected}})
      },
      createPumpChart(){
        this.pumpSeries[0].data = []
        for(let i = 0; i < this.pumpValues.length; i++){
          let date = new Date(this.pumpValues[i].date)
          date = date.getTime()-date.getTimezoneOffset() * 60 * 1000
          this.pumpSeries[0].data.push([date, this.pumpValues[i].flow])
        }
        this.pumpSeries = JSON.parse(JSON.stringify(this.pumpSeries))
      },
      createChart(){
        if(this.values.flowData){
          this.series[0].data = []
          this.series[1].data = []
          this.series[2].data = []
          for(let i = 0; i < this.values.flowData.length; i++){
            let date = new Date(this.values.flowData[i].date)
            date = date.getTime()-date.getTimezoneOffset() * 60 * 1000
            this.series[0].data.push([date, Math.round((this.values.flowData[i].flow)*100)/100])
            this.series[1].data.push([date, Math.round((this.values.flowData[i].waterTemp)*100)/100])
            this.series[2].data.push([date, Math.round((this.values.flowData[i].pressure)*100)/100])
          }
          this.series = JSON.parse(JSON.stringify(this.series))
        }

        if(this.values.rtData){
          this.rtseries[0].data = []
          this.rtseries[1].data = []
          this.rtseries[2].data = []
          for(let i = 0; i < this.values.rtData.length; i++){
            let date = new Date(this.values.rtData[i].date)
            date = date.getTime()-date.getTimezoneOffset() * 60 * 1000
            this.rtseries[0].data.push([date, Math.round((this.values.rtData[i].flow)*100)/100])
            this.rtseries[1].data.push([date, Math.round((this.values.rtData[i].waterTemp)*100)/100])
            this.rtseries[2].data.push([date, Math.round((this.values.rtData[i].pressure)*100)/100])
          }
          this.rtseries = JSON.parse(JSON.stringify(this.rtseries))
        }

        //console.log(this.values.rainData)
        if(this.values.rainData){
          this.rainserie[0].data = []
          //console.log(this.values.rainData)
          for(let i = 0; i < this.values.rainData.length; i++){
            let date = new Date(this.values.rainData[i].date)
            console.log(date.getTimezoneOffset())
            date = date.getTime()-date.getTimezoneOffset() * 60 * 1000
            this.rainserie[0].data.push([date, Math.round((this.values.rainData[i].rain)*100)/100])
          }
          this.rainserie = JSON.parse(JSON.stringify(this.rainserie))
        }

      },
      getHistory(){
        let pumpMin = this.minFormatedDate
        if(!this.minFormatedDate){
          let now = new Date()
          pumpMin = new Date(now.getFullYear(),0,0)
        }

        this.getPumpValues({param:{pumpId:this.pumpId,raw:false,minDate:pumpMin,maxDate:this.maxFormatedDate}})
        this.getData({valueType:'flowData',param:{loggerId:'logger1',type:'avg',minDate:this.minFormatedDate,maxDate:this.maxFormatedDate}})
      },
      getRealTime(){
        this.getData({valueType:'rtData',param:{loggerId:'logger1',type:'rt',minDate:this.minFormatedDate,maxDate:this.maxFormatedDate}})
        this.getData({valueType:'rainData',param:{loggerId:'rainLogger1',type:'rt',minDate:this.minFormatedDate,maxDate:this.maxFormatedDate}})
      }
    },
    mounted() {
      this.createOptions()
      this.getlastValues()
      this.getLastPump({param:{pumpId:this.pumpId}})
      this.getHistory()
      this.getLogger()
      //this.setLastPumpValue()
      setInterval(function(){
        this.getlastValues()
      }.bind(this),15 * 60 * 1000);
    },
    created() {
    },
    destroyed() {
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
